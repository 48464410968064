.ResultList {
  @apply mb-14 w-full;

  &.map {
    @apply mb-0;
  }

  &.displayGrid {
    @apply flex flex-col gap-6;
    @apply lg:grid lg:grid-cols-2;
  }

  &.displayList {
    @apply flex flex-col gap-6;
  }
}
