.BookmarkSidebar {
  @apply h-screen w-full max-w-full lg:max-w-[400px];
  @apply fixed right-0 top-0 z-[9999];
  @apply bg-white;

  transform: translate3d(100%, 0, 0);
  transition: all ease-out 0.4s;

  &.visible {
    transform: translate3d(0, 0, 0);
  }

  .header {
    @apply w-full px-6 py-1.5 lg:py-6;
    @apply flex justify-end;
    @apply shadow-submenu;
  }

  .noBookmarksWrapper {
    @apply flex h-full w-full flex-col items-center p-8;

    .title {
      @apply mb-4 text-xl font-bold;
    }

    .text {
    }
  }
}
