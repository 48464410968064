.Badge {
  @apply rounded-sm;
  @apply inline-flex items-center gap-2 text-sm;

  &.padding-md {
    @apply px-3 py-1.5;
  }

  &.padding-lg {
    @apply px-4 py-2;
  }

  &.hasBorder {
    @apply border;
  }

  &.circle {
    @apply flex items-center justify-center;
    @apply w-fit rounded-full;
  }

  &.l1 {
    @apply px-3 py-1;
  }

  &.l2 {
    @apply px-2 py-1;
  }

  &.green {
    @apply border-toom-green-500 bg-toom-green-50 text-toom-green-500;
  }

  &.red {
    @apply border-toom-red-500 bg-toom-red-50 text-toom-red-500;
  }
}
