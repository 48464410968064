.BookmarkButton {
  @apply relative;

  &.header,
  &.header_mobile {
    @apply mr-4 text-base font-normal;
  }

  &.hasBookmarks {
    svg {
      @apply fill-toom-red-500 stroke-toom-red-500;
    }
  }

  svg {
    @apply fill-white transition-all duration-100 ease-in-out;
  }

  &:hover svg {
    @apply fill-toom-red-500 stroke-toom-red-500 opacity-40;
  }

  .badge {
    position: absolute;
    top: -15px;
    right: -15px;
    @screen xl {
      top: -20px;
      right: -25px;
    }
    min-width: 32px;
    min-height: 32px;
    height: auto;
    aspect-ratio: 1;
    @apply flex flex-nowrap items-center justify-center;
  }

  svg.starAnimation {
    animation: scale 0.2s ease-in forwards;

    @keyframes scale {
      0% {
        opacity: 0;
        scale: 0;
      }
      80% {
        opacity: 1.2;
        scale: 1.2;
      }
      100% {
        opacity: 1;
        scale: 1;
      }
    }
  }
}
