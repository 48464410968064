.CheckBox {
  @apply h-4 w-4 rounded-sm;
  @apply border border-neutral-500 bg-white;

  &.on-red-bg {
    background-color: #e48587 !important;
    border-color: #e48587 !important;
  }

  .indicator svg {
    @apply h-full w-full stroke-white stroke-2;
  }

  &:hover,
  &:active {
    @apply border-toom-red-500 bg-toom-red-50;
    &[data-state="checked"] {
      @apply border-toom-red-500 bg-toom-red-500;
    }
  }

  &:focus {
    @apply border-2 border-toom-red-600 bg-toom-red-100;
    &[data-state="checked"] {
      @apply border-toom-red-600 bg-toom-red-600;
    }
  }

  &[data-disabled] {
    @apply border-neutral-400 bg-white;
    &[data-state="checked"] {
      @apply border-neutral-300 bg-neutral-300;
    }
  }

  &[data-state="checked"] {
    @apply border-toom-red-400 bg-toom-red-400;
  }
}
