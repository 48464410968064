.JobCard {
  @apply flex flex-col;
  @apply border border-neutral-200 bg-white px-6 py-8;

  &.job,
  &.map,
  &.map_sidebar {
    .bookmark {
      &.bookmarked svg {
        @apply fill-toom-red-500 stroke-toom-red-500;
      }
    }
  }

  &.job {
    header {
      @apply flex items-start justify-between;

      .timing {
        @apply mb-4 text-sm font-bold text-neutral-600;
      }
    }

    .title {
      @apply mb-4 hyphens-auto break-words text-lg font-bold md:text-xl;
    }

    .city {
      @apply mb-4 text-sm font-bold text-toom-red-500;
    }

    .targetGroups {
      @apply flex flex-wrap items-center gap-2;
    }
  }

  &.map,
  &.map_sidebar {
    @apply relative w-full;

    header {
      @apply flex items-start justify-between;

      .timing {
        @apply mb-4 text-xs font-bold text-neutral-600;
      }
    }

    .title {
      @apply w-4/5 hyphens-auto break-words text-lg font-bold;
    }

    .city {
      @apply mt-7 text-xs font-bold text-toom-red-500;
    }

    .targetGroups {
      @apply flex items-center gap-2;
    }

    .mapIcon {
      @apply cursor-pointer;

      &:hover {
        path {
          @apply fill-neutral-600 stroke-neutral-600;
        }
      }

      path {
        @apply fill-neutral-700 stroke-neutral-700;
      }

      circle {
        @apply fill-white stroke-white;
      }

      &.active {
        path {
          @apply fill-toom-red-500 stroke-toom-red-500;
        }
      }
    }
  }

  &.initiative {
    @apply border-toom-green-50 bg-toom-green-50;

    .title {
      @apply mb-2 hyphens-auto break-words text-lg font-bold;
    }

    .text {
      @apply mb-6 text-lg;
    }
  }
}
