.Button {
  @apply relative rounded-sm text-base;
  @apply flex flex-nowrap items-center justify-center gap-x-2;

  &.asButton {
    @apply border;
  }

  &.asLink {
    @apply border-none;
    background-color: transparent !important;
  }

  &.padding-none {
    @apply px-0 py-0;
  }

  &.padding-md {
    @apply px-4 py-2;
  }

  .icon {
    @apply stroke-current stroke-2;
  }

  &:active {
    @apply scale-95;
  }

  &.primary {
    @apply border-toom-red-500 bg-toom-red-500 text-white;
    //&.inverted {
    //  @apply border-white bg-white text-toom-red-500;
    //}

    &:hover {
      @apply border-toom-red-600 bg-toom-red-600;
      //&.inverted {
      //  @apply border-white bg-white text-toom-red-600;
      //}
    }

    &:active {
      @apply border-toom-red-700 bg-toom-red-700;
      //&.inverted {
      //  @apply border-white bg-white text-toom-red-700;
      //}
    }

    &:focus {
      @apply border-toom-red-200 outline-2 outline-toom-red-200;
      //&.inverted {
      //  @apply border-toom-red-300 outline-2 outline-toom-red-300;
      //}
    }

    &:disabled {
      @apply border-toom-red-50 bg-toom-red-50 text-toom-red-200;
      //&.inverted {
      //  @apply border-toom-red-50 bg-toom-red-50 text-toom-red-200;
      //}
    }
  }

  &.secondary {
    @apply border-toom-red-500 bg-white text-toom-red-500;
    //&.inverted {
    //}

    &:hover {
      @apply border-toom-red-600 text-toom-red-600;
      //&.inverted {
      //}
    }

    &:active {
      @apply border-toom-red-700 text-toom-red-700;
      //&.inverted {
      //}
    }

    &:focus {
      @apply border-toom-red-500 outline-2 outline-toom-red-500;
      //&.inverted {
      //}
    }

    &:disabled {
      @apply border-toom-red-200 text-toom-red-200;
      //&.inverted {
      //}
    }
  }

  &.tertiary {
    @apply border-neutral-700 bg-white text-neutral-700;
    //&.inverted {
    //}

    &:hover {
      @apply border-neutral-800 text-neutral-800;
      //&.inverted {
      //}
    }

    &:active {
      @apply border-black text-black;
      //&.inverted {
      //}
    }

    &:focus {
      @apply border-neutral-500 outline-2 outline-neutral-500;
      //&.inverted {
      //}
    }

    &:disabled {
      @apply border-neutral-300 text-neutral-300;
      //&.inverted {
      //}
    }
  }
}
