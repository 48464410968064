.footer {
  @apply bg-neutral-300 print:hidden;

  .topWrapper {
    @apply container grid grid-cols-1 py-12 xl:grid-cols-3;

    .links {
      @apply mb-10 xl:mb-0;
      @apply order-2 xl:order-1;

      .linksHeadline {
        @apply mb-1 select-none text-xl font-bold xl:mb-5;
      }

      .link {
        @apply mb-0.5 hover:text-toom-red;
      }
    }

    .socialmedia {
      @apply mb-6 xl:mb-0;
      @apply order-3 xl:order-2;

      .socialmediaHeadline {
        @apply mb-1 select-none text-xl font-bold xl:mb-5;
      }

      .socialmediaText {
        @apply mb-2.5 w-1/2 xl:mb-4;
      }

      .platforms {
        @apply flex flex-wrap items-center gap-4;

        li:hover {
          transition: 0.1s ease-in-out;
          transform: scale(1.1);
        }
      }
    }

    .awards {
      @apply mb-10 flex flex-wrap justify-start gap-6 xl:mb-0 xl:justify-end;
      @apply order-1 xl:order-3;

      .award {
        @apply h-[110px] w-auto bg-contain object-contain;
      }
    }

    .companyText {
      @apply col-span-full w-full text-left xl:text-right;
      @apply order-4;
    }
  }

  .bottomWrapper {
    @apply w-full bg-neutral-500 py-5;

    .innerNav {
      @apply container flex h-full flex-wrap items-center justify-between lg:flex-nowrap;

      .nav {
        @apply flex list-none flex-wrap items-center gap-4 lg:gap-7;

        .navEntry {
          @apply w-full select-none text-sm text-white lg:w-fit;

          a:hover,
          a:active,
          a:focus {
            //@apply text-toom-red;
            @apply underline underline-offset-4;
          }
        }
      }
    }
  }
}
