.Skeleton {
  @apply inline-block h-[22px] w-[150px];

  background: linear-gradient(110deg, #e5e5e5 5%, #f5f5f5 25%, #e5e5e5 33%);
  animation: 1.5s shine linear infinite;
  background-size: 200% 100%;
  background-position-x: 200%;

  &.rounded {
    @apply rounded-sm;
  }

  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }
}
