.header {
  @apply sticky top-0 w-full bg-white print:hidden;
  z-index: 9999;

  transform: translate3d(0, 0, 0);
  transition: all ease-out 0.4s;

  &.invisible {
    transform: translate3d(0, -100%, 0);
  }

  .headerInner {
    @apply container py-4;
    @apply flex items-center justify-between gap-y-4;

    @screen md {
      @apply py-6;
    }

    .logo {
      @apply relative flex-shrink-0 justify-self-start;

      width: 162px;
      height: 24px;
      @screen xl {
        width: 216px;
        height: 24px;
      }
    }

    .navWrapper {
      ul li {
        &:hover {
          a,
          span {
            svg {
              @apply rotate-180;
            }
          }
        }
      }
      ul {
        @apply hidden list-none items-center gap-4 lg:flex xl:gap-6;

        li {
          @apply relative flex min-w-fit flex-nowrap items-center gap-1;

          a,
          span {
            @apply flex min-w-fit flex-nowrap items-center gap-1;
            @apply select-none;

            font-size: 1rem;

            span {
              @apply min-w-fit;
            }

            svg {
              @apply transition-transform duration-300 ease-in-out will-change-transform;
            }
          }

          .navSubmenu {
            @apply min-w-[230px] whitespace-nowrap bg-white px-7 py-[22px] shadow-submenu;
            @apply absolute left-0 top-6 z-50 block scale-0;
            @apply origin-top transition-all duration-300 ease-in-out will-change-transform;

            &.open {
              @apply scale-100;
            }

            .separator {
              @apply w-full border-t-2 border-toom-red pt-2 font-bold;
              &:first-of-type {
                @apply border-t-0 pt-0;
              }
            }

            li {
              @apply mb-3;

              a {
                @apply text-sm text-black;
              }

              &:hover a {
                @apply text-toom-red;
              }
            }
          }
        }
      }
    }

    .mobileNavButton {
      @apply block cursor-pointer select-none lg:hidden;

      &:hover svg {
        @apply stroke-toom-red;
      }
    }

    .mobileBookmarkButton {
      @apply block cursor-pointer select-none lg:hidden;

      &:hover svg {
        @apply stroke-toom-red;
      }
    }

    .mobileNavWrapper {
      @apply fixed left-0 top-[56px] z-[9999] h-0 max-h-0 w-screen overflow-hidden bg-white sm:top-[72px];
      @apply transition-all duration-300 ease-in-out will-change-transform;

      @screen xl {
        @apply hidden;
      }

      &.open {
        @apply h-screen max-h-none overflow-y-auto;
      }

      .mobileNav {
        @apply container w-full p-6;

        li {
          @apply mb-7 font-bold;

          a {
            @apply flex items-center justify-between;

            > span,
            > div {
              @apply w-full;
            }

            > span {
              flex: 1 1 100%;
            }

            > div {
              flex: 1 1 50%;
              @apply flex items-center justify-end;
            }
          }

          &:hover {
            @apply text-toom-red;

            svg {
              @apply stroke-toom-red;
            }
          }
        }
      }
    }
  }

  a span.active {
    @apply text-toom-red;
  }
}
