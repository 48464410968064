.Section {
  @apply relative block;

  &.animation:not(&:first-of-type) {
    animation-name: slideIn;
    animation-iteration-count: 1;
    animation-timing-function: ease-out;
    animation-duration: 0.3s;
  }

  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translate3d(0, 200%, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }

  &.hasContainer {
    @apply container;
  }

  &.extraSpacing {
    @apply mb-[60px] mt-[60px];
  }

  &.paddingTop-none {
    @apply pt-0;
  }

  &.paddingBottomNone {
    @apply pb-0;
  }

  &.paddingBottom-none {
    @apply pb-0;
  }

  &.paddingTop-small {
    @apply pt-[15px] md:pt-[30px];
  }

  &.paddingBottom-small {
    @apply pb-[15px] md:pb-[30px];
  }

  &.paddingTop-medium {
    @apply pt-[30px] md:pt-[60px];
  }

  &.paddingBottom-medium {
    @apply pb-[30px] md:pb-[60px];
  }

  &.paddingTop-large {
    @apply pt-[45px] md:pt-[90px];
  }

  &.paddingBottom-large {
    @apply pb-[45px] md:pb-[90px];
  }
}
